.av-dress-gallery {
    padding: 2rem 0;

    &__subtitle {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;
        color: #808080;
    }

    &__title {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
        }
    }

    &__description {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 2rem;
        text-align: center;
        max-width: 75vw;
        margin-left: auto;
        margin-right: auto;
    }

    &__images {
        margin: 0;

        &__item {
            flex: 0 0 33.333333%;
            padding: 0.5rem;
        }
    }

    &__image {
        display: block;
        width: 100%;
        margin-bottom: 1.5rem;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        border-radius: 1rem;
        overflow: hidden;

        &--top {
            margin-top: 0;

            @include media-breakpoint-up(md) {
                margin-top: 3rem;
            }
        }

        img {
            display: block;
            width: 100%;
        }

        &--big {
            img {

            }
        }
    }
}
