.av-mobile-album-block {
    padding: 0;

    &--has-margin {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__img {
        display: block;
        width: 100%;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        border-radius: 1rem;

    }

    &__title {
        margin-top: 1rem;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;

        > a {
            color: #000;
            text-decoration: none;

            &:hover {
                color: #000;
                text-decoration: none;
            }
        }
    }
    &__description {
        font-size: 0.75rem;
        font-weight: 500;
        margin-bottom: 1rem;
        max-width: 75vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    &__button {
        max-width: 8rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        color: #000;
        border: 1px solid #212121; /* Add this border property */
        text-decoration: none;
        font-size: 0.75rem;
        font-weight: 500;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
        text-align: center;


        &:hover {
            background-color: #333;
            color: #fff;
            transform: scale(1.1);
        }
    }
}
