.wedding-album-single {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .av-heading {
        font-family: 'Be Vietnam Pro', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 1.2;
        margin-bottom: 1rem;
        color: #000;
        text-transform: capitalize;

        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }

    .wedding-album-single__images {
        &__item {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }

            img {
                display: block;
                width: 100%;
                border-radius: 10px;
            }
        }

        &__item--orientation {
            &-vertical {
                img {
                    max-width: 750px;
                    margin-left: auto;
                    margin-right: auto;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .wedding-album-single__info {
        &__content {
            margin-bottom: 1rem;
        }

        &__tags {
            margin-bottom: 1rem;

            .av-tag {
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &--style-1 {
        // Không cần định nghĩa lại .av-heading nếu không có sự khác biệt.
    }

    &--style-2 {
        .av-heading {
            text-align: center; // Chỉ định khác biệt so với style-1.
        }

        .wedding-album-single__info {
            &__content {
                text-align: center; // Chỉ định khác biệt so với style-1.
            }
        }
    }
}
